<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/smart-home/parts">
          <b-button variant="primary">
            <span class="align-middle">Back</span>
          </b-button>
        </router-link>
      </div>
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Deleted Parts">
        <b-table
          responsive="sm"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(description)="data">
            {{ data.value }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                variant="flat-success"
                class="btn-icon"
                @click="restore(data.item)"
              >
                Restore
              </b-button>

              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deletePart(data.item)"
              >
                Delete Permanently
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const smartHomeModule = createNamespacedHelpers('smartHome')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'description', label: 'description' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
    }
  },
  async mounted() {
    await this.getParts()
  },
  methods: {
    ...smartHomeModule.mapActions(['FETCH_ALL_DELETED_PARTS', 'DELETE_PART_PERMANENT', 'RESTORE_PART']),

    async getParts() {
      try {
        this.isBusy = true
        this.items = await this.FETCH_ALL_DELETED_PARTS()
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    async deletePart(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to permanently delete this part', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_PART_PERMANENT(item.id)
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Part deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Part has been permanently deleted!',
                  },
                })
                this.getParts()
              }
              this.loader = false
            } catch (error) {
              this.loader = false
            }
          }
        })
    },
    async restore(item) {
      try {
        this.loader = true
        const resp = await this.RESTORE_PART(item.id)
        if (resp) {
          this.getParts()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Part restored!',
              icon: 'PlusIcon',
              variant: 'success',
              text: 'Part has been restored successfully!',
            },
          })
        }
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },
  },
}
</script>
